import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
  const auth = useAuthStore()

  if (!auth.me) {
    return navigateTo({
      name: 'login',
      query: {
        redirect: to.fullPath
      }
    })
  }
})
